export const colors = {
  yottaBg: "#1E1E1E",
  yottaHighlightBg: "#1C1C1C",
  yottaHighlightBorder: "#2D2D2D",
  yottaBlackNeutral: "#1B1B1B",
  yottaBlack: "#252525",
  yottaGrey: "#656565",
  yottaGreyNatural: "#414040",
  yottaGreyDisabled: "#464646",
  yottaGreyText: "#6D6D6D",
  yottaGrayFont: "#9C9C9C",
  yottaGrayNeutral: "#F3F4EF",

  yottaGreen: "#A2CC39",
  yottaWhite: "#FFFFFF",
  yottaBlue: "#000AFF",
  yottaLightBlue: "#000AFF",
  yottaPurple: "#AE3ACB",
  yottaYellow: "#EBFF00",
  yottaDarkYellow: "#DEAC51",
  yottaOrange: "#FF6314",
  yottaDarkRed: "#A83434",

  yottaGreenAlert: "#a2cc39",
  yottaRedAlert: "#fa0001",
  yottaOrangeAlert: "#f8bf00",
  yottaGrayAlert: "#AAAAAA",
  yottaPinkAlert: "#ea02ff",
  yottaPurpleAlert: "#7700ff",
  yottaBlueAlert: "#2e25ec",

  yottaPastelRed: "#ED6666",
  yottaPastelGreen: "#66CE66",
  yottaPastelBlue: "#5571B9",

  tintedWhite: "#F6FAEB",
  tintedBlack: "#101505",

  black: "#0c1015",
  gray: {
    "50": "#fafafa",
    "100": "#f1f1f1",
    "200": "#e7e7e7",
    "300": "#d4d4d4",
    "400": "#adadad",
    "500": "#7f7f7f",
    "600": "#545454",
    "700": "#373737",
    "800": "#202020",
    "900": "#191919",
  },
  teal: {
    "50": "#f4fbfc",
    "100": "#ceeef2",
    "200": "#a2dfe7",
    "300": "#6bccd9",
    "400": "#36b0c0",
    "500": "#2e96a3",
    "600": "#257a85",
    "700": "#1d5e67",
    "800": "#184f56",
    "900": "#144147",
  },
  purple: {
    "50": "#f9f6fd",
    "100": "#e6daf5",
    "200": "#d4beee",
    "300": "#b996e4",
    "400": "#a67add",
    "500": "#8d54d3",
    "600": "#7a39cb",
    "700": "#642fa6",
    "800": "#522689",
    "900": "#3d1c65",
  },
  red: {
    "50": "#fcf6f5",
    "100": "#f5dad7",
    "200": "#ecbab3",
    "300": "#e09187",
    "400": "#d9796d",
    "500": "#cf5646",
    "600": "#b74233",
    "700": "#933529",
    "800": "#7d2d23",
    "900": "#5a2119",
  },
  primary: {
    "50": "#f5f9e9",
    "100": "#d5e8a6",
    "200": "#a2cc39",
    "300": "#8eb232",
    "400": "#7fa02d",
    "500": "#6b8726",
    "600": "#5a7120",
    "700": "#485b19",
    "800": "#3d4d15",
    "900": "#2c370f",
  },

  maps: {
    light: {
      water: "#CCE0E5",
      road: "#FFFFFF",
      landcover: "#E5E8E1",
      manmade: "#D6D7D0",
      terrain: "#C8C9BD",
    },
    dark: {
      water: "#0D1216",
      road: "#20211E",
      landcover: "#3E3E38",
      manmade: "#51524A",
      terrain: "#65665C",
    },
  },
};
